<template>
  <b-modal
    id="refNoWarningModal"
    ref="refNoWarningModal"
    :title="modalTitle"
    header-bg-variant="warning"
    header-text-variant="dark"
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div>
      <small v-if="errorMessage" class="text-muted d-block mb-3">
        <i class="fas fa-exclamation-circle mr-1"></i>
        {{ errorMessage }}
      </small>
      <b-form @submit.prevent="handleSubmit">
        <b-form-group label-for="refNo">
          <!-- Custom label with info icon -->
          <template #label>
            <div class="d-flex align-items-center">
              <span>กรุณากรอกเลข REF NO เพื่อพิมพ์ใบเสร็จ</span>
              <b-button
                v-b-popover.hover.right="popoverConfig"
                variant="link"
                class="p-0 ml-2"
                title="ตัวอย่าง Sale Slip "
              >
                <i class="fas fa-info-circle"></i>
              </b-button>
            </div>
            <!-- Added description text -->
            <small class="text-muted d-block mt-1">
              ใส่เลข "0" ถ้าไม่ต้องการบันทึก REF NO
            </small>
          </template>
          <b-form-input
            id="refNo"
            v-model="refNo"
            required
            trim
            :state="isValid"
          ></b-form-input>

          <b-form-invalid-feedback> ตัวเลขเท่านั้น </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex justify-content-end mt-4">
          <b-button variant="secondary" class="mr-2" @click="handleCancel">
            ยกเลิก
          </b-button>
          <b-button
            variant="primary"
            :disabled="!isValid"
            @click="handleSubmit"
          >
            พิมพ์ใบเสร็จ
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import slipSample from "../../assets/img/slipSample.jpg";
import { ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT } from "@/constants";

export default {
  name: "RefWarningModal",
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      refNo: "",
      slipSample,
      popoverConfig: {
        content: `<div class="p-2">
          <img src="${slipSample}" alt="ตัวอย่างใบเสร็จ" class="img-fluid mb-2" style="max-width: 200px"/>
        </div>`,
        html: true,
      },
    };
  },
  computed: {
    isValid() {
      if (!this.refNo) return false;
      return /^\d+$/.test(this.refNo);
    },
    modalTitle() {
      return this.errorMessage === ERROR_MESSAGE_USER_REQUEST_PRINT_RECEIPT
        ? "ผู้ใช้ขอพิมพ์ใบเสร็จ"
        : "ไม่สามารถส่งข้อมูลได้ไปยังเครื่อง EDC ได้";
    },
  },
  methods: {
    show() {
      this.refNo = "";
      this.$refs.refNoWarningModal.show();
    },
    hide() {
      this.$refs.refNoWarningModal.hide();
    },
    handleSubmit() {
      if (this.isValid) {
        this.$emit("ref-confirm", { referenceId: this.refNo });
        this.hide();
      }
    },
    handleCancel() {
      this.$emit("ref-cancel");
      this.hide();
    },
  },
};
</script>